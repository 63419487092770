import crashTest from '../assets/crash_test.webp'
import crashTestPng from '../assets/crash_test.png'
import signUp from '../assets/signUp.png'
import email from '../assets/email.png'
import contract from '../assets/contract.png'
import phone from '../assets/phone.png'
import recover from '../assets/recover.png'
import repair from '../assets/repair.png'
import key from '../assets/key.png'
import fees from '../assets/fees.png'
import membership from '../assets/membership.png'
import own from '../assets/own.png'


export const howParallax = {
    banner: true,
    color: '#baf1247a',
    alignment: 'left',
    image: crashTest,
    pngImage: crashTestPng,
    title: `How does it work?`,
    h1: true,
    paragraphContent: [
        {
            boldText: 'OK this is a bit long winded, but hopefully explains how motor legal protection really works and how Free Motor Legal works'
        },
        {
            boldText: 'The brutal and honest truth'
        },
        {
            text: 'When you buy a  motor insurance policy, whether it is third party fire & theft cover, or fully comprehensive, you will often also be “up-sold” a range of add-on products like breakdown cover and motor legal protection (legal expenses insurance).'
        },
        {
            text: 'These are separate policies which act in addition to your main motor insurance policy. Motor Legal Protection policies are there to use for when you have a collision that was not your fault and you need to claim back losses and expenses from the other party at fault. These are losses that are not covered by your own motor insurance policy and are called “uninsured losses”. Such examples could be lost earnings, policy excess, travel costs, hire car charges or compensation for personal injury.'
        },
        {
            text: 'These policies essentially direct you to a lawyer of their choosing so they can claim such losses or expenses and the costs risks are covered by the policy and you should keep 100% of your compensation. The policies require that you must have a claim with “reasonable prospects of success” to enable the lawyers to act.'
        },
        {
            text: 'What happens in reality if you have a non-fault claim is that the policy you bought for £30 for £100k of legal cover is just used as a claims capture mechanism; whereby you ring to report a claim and they pass you to a law firm on their panel and the lawyers will represent you and claim back the losses you have suffered or pursue the injury compensation claim for you. You could also be passed through to a hire company to manage your vehicle claim and provide you with a replacement hire car/bike/van whilst you don’t have the use of your own damaged vehicle. Fair enough you might think.'
        },
        {
            text: 'However what really goes on is that you get passed to the law firm on the panel, who have an agreement with the insurer/broker/price comparison site you purchased the policy from that in the event a claim does not succeed, the lawyers will never go back knocking on the door of the underwriters of the motor legal protection policy for payment of their costs or your opponent’s costs. The lawyers work on the basis of they get paid fees on the cases they win and they suck their thumbs on the cases they lose. In return for being on the panel they pay “marketing fees” to the people you bought the policy from for receiving injury compensation claims. We mentioned hire companies earlier. Well they also pay marketing fees back to the people you bought the policy from. So not a bad little earner for them.'
        },
        {
            text: 'So the broker/insurer/price comparison site you purchased your policy from has sold you a motor legal protection policy for probably £30.00 which cost them about 50P – a 6000% mark up!'
        },
        {
            text: 'When you use the policy and say have an injury compensation claim, they will receive several hundred pounds in kick backs from the law firm they appoint for you and again several hundred pounds commission from the hire company they put you onto. All this time, the underwriters of the policy know they are not going to get hit up with any claims for legal costs because the lawyers have agreed to a “hold harmless” agreement (they won’t acknowledge such agreements exist because they would be in breach of Solicitor’s Regulation Authority rules) but they do exist. This means that the lawyers can deal with your claim without using the policy, so what has the policy been used for other than to get you in front of the lawyers and earn those who sold you the policy some money?'
        },
        {
            text: 'The policy has just been used as a claims capture mechanism. Granted you get the services of the lawyer and the replacement hire car, but why did you pay £30.00 for something which just essentially passed your claim details onto business partners and then did nothing?'
        },
        {
            text: 'This is where the founder of Free Motor Legal, Lee Jones, saw that there was a way to offer motorists the same services, but without there needing to be a motor legal protection policy. He worked for those law firms and saw that the policies were never being “used” by the lawyers.'
        },
        {
            boldText: 'So how does Free Motor Legal work?'
        },
        {
            text: 'Well we are not selling you or giving away a “motor legal protection policy”. We are offering a free membership service to any motorists living in England, Scotland & Wales to deal with motor vehicle accidents arising in England, Scotland & Wales. By joining you gain free lifetime membership and you don’t need to renew the membership each year as your membership just rolls on year after year.'
        },
        {
            text: 'Essentially you have joined a free club. Also, motor legal protection policies sold by the insurers usually just cover you for claims arising from the use of the vehicle you have insured with them. So if you had say 2 cars, you could not have just one motor legal protection policy to cover you for claims on the 2nd car as well, you would have to buy 2 separate policies.'
        },
        {
            text: 'With Free Motor Legal it is you that is the member, not the vehicle. So you can have unlimited cars, van, bikes and the membership assists you with claims arising out of the use of any of your vehicles. Your household family members can also use your membership, but as it is free, why not just have them join anyway if they have their own vehicle?'
        },
        {
            text: 'By being a member, if you have a collision which was not your fault, providing you have a claim with “reasonable prospects of success” (the same criteria the insurers have in their policy wordings) we will have your claim assessed and if you need a replacement hire car, our partners will arrange this and seek the costs of it from the insurers of the other party at fault for the collision. Likewise if you have losses to claim back or compensation to seek then legal assistance is available under a no win-no fee agreement but without the deduction of any fees or expenses from your successful claim.'
        },
        {
            text: 'So you would receive the same outcome for a claim that you would if you had a £30.00 motor legal protection policy (no deductions or fees taken)'
        },
        {
            boldText: 'How can we do this?'
        },
        {
            text: 'Well, we are simply replicating what the motor legal protection marketplace does. We have a member instead of a policyholder. When the member needs assistance they get the services of a hire company and/or lawyers who will assist you without charging you (just like the motor legal protection policies do).'
        },
        {
            text: 'If they succeed with your claim, the lawyers will be paid their fees and expenses from the insurance company of the party at fault. If they don’t succeed with your case, they have to suck their thumbs, just like the insurer panel firms.  You do not stand liable for costs on a case which does not succeed because legislation provides that you are protected against paying opponent’s costs if you do not succeed with your claim. The only time you would be liable for your own or opponent’s costs would be if you breached the terms of the no win no fee agreement with the lawyers. Such examples would be if your claim was found to be fraudulent or fundamentally dishonest. In this situation, even if you had a motor legal protection policy, they would not agree to indemnify (cover) you for such fees.'
        },
        {
            text: 'How is this free and how does Free Motor Legal benefit?'
        },
        {
            text: 'As we are not being asked to pay lawyers fees, we have no such costs to entertain. Our overheads are low as we are internet based and the services provided to our members (hire cars and lawyers) are outsourced, so we don’t have expensive offices with hundreds of bums on seats in call centres. Our business partners do and have those expenses! We receive commission payments from some of our service providers (actually just the hire companies). We make some money if you have a claim, we just didn’t charge you £30 each year only to just pass you on to a lawyer or hire company like the insurance industry do.'
        },
        {
            boldText: 'We are the free alternative to motor legal protection insurance in England, Scotland & Wales!'
        },
        {
            text: 'The fact that the legal expenses insurance marketplace works how it does has allowed this free service to be developed as a direct alternative.'
        },
    ],
}

export const columns = {
    title: 'Now you can save all of that money. Here’s how:',
    leftColumn: [
        {
            image: signUp,
            reason: `You sign up to Free Motor Legal right now. You only do it once, no need to renew every year. And it's free.`
        },
        {
            image: email,
            reason: 'You receive by email a membership pack including a free accident survival pack with a glove box guide that tells you exactly what to do after a collision.'
        },
        {
            image: contract,
            reason: 'When you take out new / renew your motor insurance, simply opt out of the thing called Motor Legal Cover or Motor Legal Protection to save yourself approximately £30.' 
        },
        {
            image: phone,
            reason: 'If you are in an accident, you call us and our partners will advise you on what to do next including what details to get at the scene. We have a 24/7 365 days a year freephone claims reporting number.'
        },
        {
            image: recover,
            reason: `We arrange for you and your car to be recovered if you can't drive it, with your choice of repairing garage.`
        },
        {
            image: repair,
            reason: 'Assessment and repairs, with no policy excess to pay.*'
        }
    ],
    rightColumn: [
        {
            image: key,
            reason: 'Our partners can arrange you a like-for-like vehicle to use while yours is being fixed to keep you on the road. *'
        },
        {
            image: fees,
            reason: 'Legal Assistance to claim any losses or compensation from the at-fault party, with no fees or deductions applied.'
        },
        {
            image: membership,
            reason: `Your membership benefits extend to family and friends as well as passengers in your vehicle. You even get assistance if you are a passenger in somebody else's vehicle or on public transport.`
        },
        {
            image: own,
            reason: `Your insurance premiums are not affected and you're not out of pocket for an excess if you have not claimed through your own motor insurance policy`
        }
    ],
    disclaimer: '* Some terms & conditions may apply. See our T&Cs for full details.'
}

export const banner = {
    small: false,
    bgColor: "#0bbcbf",
    padding: true,
    inlineBtn: [
        {
            link: 'true',
            label: 'Got more questions? Click here',
            url: '/how-it-works/questions/'
        },
        {
            link: 'true',
            label: 'Ready to sign up? Click here',
            url: '/sign-up-to-fml/'
        }
    ]
  }


  