import React from "react";
import Layout from "../layouts";
import ParallaxComponent from "../components/parallax-component/parallax-component"
import { howParallax, columns, banner } from "../utils/howItWorks";
import "../styles/pages/privacy-policy.scss";
import InfoColumns from "../components/info-columns/info-columns";
import Banner from "../components/banner/banner";

const HowItWorks = () => {

    return (

        <Layout SEOTitle={"How it works | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <ParallaxComponent props={howParallax} />
            <InfoColumns props={columns} />
            <Banner props={banner} />
            
        </Layout>
    )
}

export default HowItWorks;